import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ColorPuzzle } from "../pages/puzzles/color/ColorPuzzle";

export function Puzzle() {
  const { puzzle } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!["🔴", "🔵", "🟣"].includes(puzzle)) {
      navigate("/NotFound", { replace: true });
    }
  }, [puzzle, navigate]);

  if (["🔴", "🔵", "🟣"].includes(puzzle)) {
    return <ColorPuzzle color={puzzle} />;
  }

  return null;
}

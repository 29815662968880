import { functions } from "../config/firebase";
import { httpsCallable } from "firebase/functions";
import { convertNamesToColors } from "../pages/puzzles/color/components/ColorRectangle";
const BASE_URL = "YOUR_API_ENDPOINT";

export const PuzzleService = {
  getColorPuzzle: async (color) => {
    const getColorPuzzle = httpsCallable(functions, "GetColorPuzzle");

    return getColorPuzzle({ color: color })
      .then((result) => {
        return convertNamesToColors(result.data.pattern);
      })
      .catch((error) => {
        console.error("An error occurred: ", error);
      });
  },

  verifyLocation: async (userId) => {
    const response = await fetch(`${BASE_URL}/users/${userId}`);

    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }

    return await response.json();
  },

  validateColorPattern: async (pattern) => {
    const validateColorPattern = httpsCallable(
      functions,
      "ValidateColorPattern",
    );

    return validateColorPattern({ attempt: pattern })
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.error("An error occurred: ", error);
        return false;
      });
  },

  verifyColorCompletion: async (validationKey) => {
    const getColorPuzzle = httpsCallable(functions, "VerifyPuzzleCompletion");

    return getColorPuzzle({ key: validationKey })
      .then((result) => {
        return result.data.verified;
      })
      .catch((error) => {
        console.error("An error occurred: ", error);
      });
  },

  submitFormData: async (formData) => {
    const submitFormData = httpsCallable(functions, "SubmitFormData");
    return submitFormData(formData)
      .then((result) => {
        return result.data.success;
      })
      .catch((error) => {
        console.error("An error occurred: ", error);
      });
  },
};

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PuzzleService } from "../../../services/PuzzleService";
import {
  ColorRectangle,
  Color,
  convertColorsToName,
} from "./components/ColorRectangle";
import "./ColorPuzzle.css";

const colorCycle = [Color.RED, Color.BLUE, Color.PURPLE, Color.NONE];

export function ColorChallenge({ color }) {
  const [cluePattern, setCluePattern] = useState(Array(6).fill(Color.NONE));
  const [userGuess, setUserGuess] = useState(Array(6).fill(Color.NONE));
  const [error, setError] = useState(null);
  const [hasPermissions, setHasPermissions] = useState(false);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const navigate = useNavigate();

  /*** Animation logic ***/
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      // const result = await navigator.permissions.query({ name: "geolocation" });
      //
      // if (result.state !== "granted") {
      //   navigate(`/📍?puzzle=${color}`, { state: { puzzle: "color" } });
      // }

      setHasPermissions(true);
    };

    fetchUserPermissions();
  }, [navigate, color]);

  useEffect(() => {
    if (!hasPermissions) return;

    const timer1 = setTimeout(() => setStep1(true), 500);
    const timer2 = setTimeout(() => setStep2(true), 1500);
    const timer3 = setTimeout(() => setStep3(true), 2500);
    const timer4 = setTimeout(() => setStep4(true), 4500);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
    };
  }, [hasPermissions]);

  useEffect(() => {
    if (!hasPermissions) return;

    const fetchPattern = async () => {
      let result =
        (await PuzzleService.getColorPuzzle(color)) ||
        Array(6).fill(Color.NONE);
      setCluePattern(result);
    };

    fetchPattern();
  }, [color, hasPermissions]);

  const canSubmit = () => {
    return (
      userGuess.every((el) => el !== Color.NONE) &&
      !error &&
      !isAwaitingResponse
    );
  };

  const handleTap = (index) => {
    setUserGuess((prevElements) => {
      const currentColor =
        prevElements[index] === Color.NONE ? Color.Purple : prevElements[index];
      const nextColorIndex =
        (colorCycle.indexOf(currentColor) + 1) % (colorCycle.length - 1);
      const newElements = [...prevElements];
      newElements[index] = colorCycle[nextColorIndex];

      return newElements;
    });
  };

  const validate = async () => {
    if (userGuess.find((el) => el === Color.NONE)) return false;

    const colorNames = convertColorsToName(userGuess);
    setIsAwaitingResponse(true);
    return await PuzzleService.validateColorPattern(JSON.stringify(colorNames));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResult = await validate();
    if (!validationResult.correct) {
      setError(validationResult.message);
      setTimeout(() => setError(null), 2000);
    } else {
      navigate("/puzzles/🟣", {
        state: { validationKey: validationResult.key },
      });
    }
    setIsAwaitingResponse(false);
  };

  return (
    <div className="p-4 size-full">
      <h1
        className={`text-display-lg text-center mb-12 ${step1 ? "visible" : ""}`}
        id="color-emoji-header"
      >
        {color}
      </h1>
      <div className="flex flex-col gap-12 justify-evenly items-center h-[70%]">
        <div className="text-center">
          <p id="color-clue-1" className={`${step2 ? "visible" : ""}`}>
            Combine your halves to make the hue
          </p>
          <p id="color-clue-2" className={`${step3 ? "visible" : ""}`}>
            Committing in lockstep is the clue
          </p>
        </div>

        <div
          id="color-puzzle-box"
          className={`bg-slate-100 relative ${error ? "error-flash" : ""} ${step4 ? "visible" : ""}
      `}
        >
          {error && (
            <div
              id="color-error-overlay"
              className="absolute top-0 left-0 size-full flex flex-col justify-center"
            >
              <p className="text-center text-headline-sm">{error}</p>
            </div>
          )}
          {cluePattern.map((el, i) => (
            <ColorRectangle key={i} color={el} />
          ))}

          {userGuess.map((el, i) => (
            <ColorRectangle onClick={() => handleTap(i)} key={i} color={el} />
          ))}
        </div>

        <button
          disabled={!canSubmit()}
          id="color-challenge-submit-button"
          className={`btn btn-blue w-[135px] shadow-lg ${isAwaitingResponse ? "blinking" : ""} ${step4 ? "visible" : ""}`}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function NotFound() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/🌅", { replace: true });
  }, [navigate]);

  return (
    <div className="size-full flex flex-col gap-4 items-center justify-center text-white">
      <p className="text-display-lg">🌅</p>
      <i>wander and wonder together</i>
    </div>
  );
}

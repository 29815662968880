// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#grant-permissions {
  font-weight: 700;
  font-style: italic;
  --tw-text-opacity: 1;
  color: rgb(125 211 252 / var(--tw-text-opacity));
  opacity: 0;
  transition: opacity ease-in 1.2s;
}

#grant-permissions.visible {
  opacity: 1;
}
#location-incompatible-device-text,
#location-prompt-text {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

#location-incompatible-device-text.visible,
#location-prompt-text.visible {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/location/LocationPermission.css"],"names":[],"mappings":"AACE;EAAA,gBAA8C;EAA9C,kBAA8C;EAA9C,oBAA8C;EAA9C,gDAA8C;EAA9C,UAA8C;EAC9C;AAD8C;;AAIhD;EACE,UAAU;AACZ;AACA;;EAEE,UAAU;EACV,kCAAkC;AACpC;;AAEA;;EAEE,UAAU;AACZ","sourcesContent":["#grant-permissions {\n  @apply text-sky-300 opacity-0 italic font-bold;\n  transition: opacity ease-in 1.2s;\n}\n\n#grant-permissions.visible {\n  opacity: 1;\n}\n#location-incompatible-device-text,\n#location-prompt-text {\n  opacity: 0;\n  transition: opacity 1s ease-in-out;\n}\n\n#location-incompatible-device-text.visible,\n#location-prompt-text.visible {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

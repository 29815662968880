import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { NotFound } from "./components/NotFound";
import { Puzzle } from "./components/Puzzle";
import { MobileOnly } from "./pages/MobileOnly";
import { LocationPermission } from "./pages/location/LocationPermission";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobile = /android|iPad|iPhone|iPod/i.test(userAgent.toLowerCase());

    if (!isMobile) navigate("/dot2dashdot-4dot-3dash-dashdot-dot");
  }, [navigate]);

  return (
    <Routes>
      <Route path="/puzzles/:puzzle" element={<Puzzle />} />
      <Route
        path="dot2dashdot-4dot-3dash-dashdot-dot"
        element={<MobileOnly />}
      />
      <Route path="*" element={<NotFound />} />
      <Route path="📍" element={<LocationPermission />} />
    </Routes>
  );
}

export default App;

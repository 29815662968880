import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PuzzleService } from "../../../services/PuzzleService";
import "./ColorPuzzle.css";

export function ColorSuccess() {
  const [didCompletePuzzle, setDidCompletePuzzle] = useState(false);
  const [venmo, setVenmo] = useState("");
  const [isInterested, setIsInterested] = useState(null);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [didSubmit, setDidSubmit] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);

  useEffect(() => {
    const timer1 = setTimeout(() => setStep1(true), 500);
    const timer2 = setTimeout(() => setStep2(true), 2000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  useEffect(() => {
    if (!step1) {
      const timer1 = setTimeout(() => setStep1(true), 500);

      return () => clearTimeout(timer1);
    }
  }, [step1]);

  useEffect(() => {
    if (!step2) {
      const timer2 = setTimeout(() => setStep2(true), 2000);

      return () => clearTimeout(timer2);
    }
  }, [step2]);

  useEffect(() => {
    const validateRequest = async () => {
      const isValid = await PuzzleService.verifyColorCompletion(
        state.validationKey,
      );
      if (!isValid) navigate("/NotFound");

      setDidCompletePuzzle(true);
    };

    validateRequest();
  }, [state, navigate]);

  const handleVenmoChange = (e) => {
    const value = e.target.value;
    setVenmo(value.startsWith("@") ? value : "@" + value);
  };

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!venmo.replace("@", "")) newErrors.venmo = "Venmo is required";
    if (isInterested === null)
      newErrors.isInterested = "Interest selection is required";
    if (isInterested && !emailRegex.test(email))
      newErrors.email = "Email is required if interested";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (validate()) {
      await PuzzleService.submitFormData({
        venmo,
        email,
        key: state.validationKey,
      });
      console.log({ venmo, email, key: state.validationKey });
      setDidSubmit(true);
      setStep1(false);
      setStep2(false);
    }
    setIsSubmitting(false);
  };

  if (!didCompletePuzzle) return <></>;

  if (didSubmit) {
    return (
      <div className="grow flex flex-col items-center justify-center gap-4 text-white">
        <p
          className={`${step1 ? "visible" : ""}`}
          id="submission-success-text-1"
        >
          You will receive your payment shortly
        </p>
        <i
          className={`${step2 ? "visible" : ""}`}
          id="submission-success-text-2"
        >
          wander and wonder together
        </i>
      </div>
    );
  }

  return (
    <>
      <h1
        className={`text-display-lg my-6 text-slate-100 text-center ${step1 ? "visible" : ""}`}
        id="form-success-header"
      >
        Success
      </h1>
      <div className="grow flex">
        <form
          onSubmit={handleSubmit}
          className={`bg-slate-100 p-6 rounded-lg w-[90%] max-w-[400px] shadow-lg text-center m-auto flex flex-col gap-4 ${step2 ? "visible" : ""}`}
          id="contact-submission-form"
        >
          <div>
            <label className="block text-left text-gray-700 mb-2">Venmo</label>
            <input
              type="text"
              value={venmo}
              onChange={handleVenmoChange}
              className="w-full px-3 py-2 border rounded mb-3 bg-slate-300"
              placeholder="@yourvenmo"
            />
          </div>
          {errors.venmo && (
            <span className="text-red-500 text-sm">{errors.venmo}</span>
          )}

          <div>
            <p className="my-4 text-gray-700 text-left">
              Interested in giving feedback to the creators?
            </p>
            <div className="flex justify-around mb-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="interested"
                  value="yes"
                  onChange={() => setIsInterested(true)}
                  className="mr-2"
                />
                Yes
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="interested"
                  value="no"
                  onChange={() => setIsInterested(false)}
                  className="mr-2"
                />
                No
              </label>
            </div>
            {errors.isInterested && (
              <span className="text-red-500 text-sm">
                {errors.isInterested}
              </span>
            )}
          </div>

          {isInterested && (
            <div>
              <label className="block text-left text-gray-700 mb-2">
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 border rounded mb-3 bg-slate-300"
                placeholder="you@example.com"
              />
              {errors.email && (
                <span className="text-red-500 text-sm">{errors.email}</span>
              )}
            </div>
          )}

          <button
            disabled={!step2}
            className={`btn btn-blue w-[150px] mx-auto ${isSubmitting ? ".blinking" : ""}`}
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
}

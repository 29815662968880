export const Color = Object.freeze({
  RED: "bg-red-700",
  BLUE: "bg-blue-700",
  PURPLE: "bg-violet-700",
  NONE: "bg-slate-400",
});

export const convertColorsToName = (colors) => {
  return colors.map((value) =>
    Object.keys(Color).find((key) => Color[key] === value),
  );
};

export const convertNamesToColors = (names) => {
  return names.map((color) => {
    return Color[color] || Color.NONE;
  });
};

export function ColorRectangle({ color, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`${color} ${color === Color.NONE ? "untapped" : ""} color-rectangle`}
    ></div>
  );
}

import { ColorChallenge } from "./ColorChallenge";
import { ColorSuccess } from "./ColorSuccess";
import "./ColorPuzzle.css";

export function ColorPuzzle({ color }) {
  switch (color) {
    case "🔴":
    case "🔵":
      return <ColorChallenge color={color} />;
    case "🟣":
      return <ColorSuccess />;
    default:
      break; // TODO: redirect to unknown request page
  }
}

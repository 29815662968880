import { functions } from "../config/firebase";
import { httpsCallable } from "firebase/functions";

export const UtilityService = {
  isInRange: async (puzzleData) => {
    const checkLocation = httpsCallable(functions, "CheckLocation");

    return checkLocation({
      latitude: puzzleData.latitude,
      longitude: puzzleData.longitude,
      quest: puzzleData.quest,
    })
      .then((result) => {
        return result.data.withinRange;
      })
      .catch((error) => {
        console.error("An error occurred: ", error);
      });
  },
};

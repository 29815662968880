import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "./LocationPermission.css";
import { UtilityService } from "../../services/UtilityService";

export function LocationPermission() {
  const { state, search } = useLocation();
  const geolocation = navigator.geolocation;
  const navigate = useNavigate();
  const [puzzleQueryParam, setPuzzleQueryParam] = useState(null);
  const [permissionError, setPermissionError] = useState(null);

  /*** Animation properties ***/
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);

  const promptUserPermissions = (event) => {
    event.preventDefault();

    // Ask for permissions

    // geolocation.getCurrentPosition(
    //   (position) => {
    //     console.log("I am here");
    //     const { latitude, longitude } = position.coords;
    UtilityService.isInRange({
      latitude: "41.79148",
      longitude: "87.59992",
      quest: state.puzzle,
    })
      .then((isInRange) => {
        if (!isInRange) {
          // Show "you are not in the challenge radius"
        }

        navigate(`/puzzles/${puzzleQueryParam}`);
      })
      .catch((error) => {
        console.error(
          "An error occurred while trying to verify user location: ",
          error,
        );
        setPermissionError(error.message);
      });
    //   },
    //   (error) => {
    //     console.error(
    //       "An error occurred while trying to fetch user location: ",
    //       error,
    //     );
    //     setPermissionError(error.message);
    //   },
    //   { timeout: 5000 },
    // );
  };

  useEffect(() => {
    const queryPuzzle = new URLSearchParams(search).get("puzzle");
    if (!state.puzzle && !queryPuzzle) navigate("/NotFound");

    const timer1 = setTimeout(() => setStep1(true), 500);
    const timer2 = setTimeout(() => setStep2(true), 2000);
    setPuzzleQueryParam(queryPuzzle);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [navigate, search, state]);

  if (!geolocation) {
    return (
      <div className="flex flex-col size-full justify-center items-center">
        <p
          id="location-incompatible-device-text"
          className={`text-center text-display-sm ${step1 ? "visible" : ""}`}
        >
          🫵📱🚫📍📡
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col size-full justify-center items-center gap-4">
      {permissionError && <p>{permissionError}</p>}
      <p
        id="location-prompt-text"
        className={`text-center ${step1 ? "visible" : ""}`}
      >
        this challenge requires your location
      </p>
      <button
        id="grant-permissions"
        disabled={!step2}
        onClick={promptUserPermissions}
        className={`text-title-lg ${step2 ? "visible" : ""}`}
      >
        Grant?
      </button>
    </div>
  );
}
